import React from 'react';
import NavBar from './Components/NavBar/NavBar';
import Rhoeas from './Components/Main Text/Rhoeas';
import RhoeasGames from './Components/RhoeasGames/RhoeasGames';
import MCPrivacyPolicy from './Components/RhoeasGames/Math Cheer/MCPrivacyPolicy'
import { BrowserRouter, Switch, Route } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <div>
      <NavBar/>
        <Switch>
          <Route exact path='/' component={Rhoeas}></Route>
          <Route path='/games' component={RhoeasGames}></Route>
          <Route path='/mcprivacy' component={MCPrivacyPolicy}></Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
