import React, {Component} from 'react';
import styles from './NavBar.module.css';
import {Link} from 'react-router-dom';

class NavBar extends Component{
    render() {
        return(
            <div>
                <ul className={styles.topnav}>
                    <li className={styles.options}><Link to='/'> Home</Link></li>
                    <li className={styles.options}><Link to='/games'>RhoeasGames</Link></li>
                    <li className={styles.options}><Link to='/'>RhoeasApps</Link></li>
                    <li className={styles.about}><Link to='/'>About</Link></li>
                </ul>
            </div>
        )
    }
}

export default NavBar;

