import React from 'react';
import styles from './Rhoeas.module.css';
class Rhoeas extends React.Component{
    render() {
        return(
            <div className={styles.RhoeasDiv}>
                <h1 className={styles.Rhoeas}>Rhoeas Digital</h1>
            </div>
        )
    }
}

export default Rhoeas;