import React, {Component} from 'react';
import styles from './MathCheer.module.css';

class MCPrivacyPolicy extends Component {

   

    render() {
    const mathCheerPrivacyPolicy = 
    <div className={styles.privacyPolicyDiv}>
        <h2 className={styles.privacyPolicyMainHeader}>Math Cheer Privacy Policy</h2>
        <h3>Overview</h3>
        <p> This Privacy Policy describes the information collected by “Math Cheer” mobile game. 
            You provide consent to this Policy when you  access and use “Math Cheer” game application and related services. 
            Your information  will not be  used or shared with anyone except as described in this Privacy Policy. </p>
        <h3>Collection of Information</h3>
        <p>This application is primarily designed for children, do not collect personal information and show only non-personalized, child-directed ads. 
            We do not collect or require users to enter any of their personal information such as your full name, address, phone number or email address. 
            We do not use or collect your precise geographic location. </p>
        <h3>Children’s Privacy</h3>

        <p> Math Cheer application is developed with many precautions to protect the online privacy of children.
            Whenever we refer to “children” or "child" in this Privacy Policy, we mean children or child under the age of 13.
            This application comply with the Children’s Online Privacy Protection Act . 
            We do not knowingly collect personal information from children under the age of 13.
            This application do not show Interest-based or personalized advertising.  
            When a user identifies himself or herself as a child under the age of 13 through a support request or through any feedback, 
            we will not collect, store or use, and will delete in a secure manner, any personal information of such user. 
            If you are a parent of a child under 13 years of age and you believe your child has provided us with personal Information, 
            please contact us and we will take necessary actions to remove those information</p>
        
        <h3>Third Party Services</h3>

        <p>This application may use third party services listed below. </p>

        <h4>Admob</h4>
        <p>AdMob is an advertising service provided by AdMob Google Inc. 
        'Math Cheer' game uses AdMob during gameplay. Please read the overview of how they use information from sites or apps that use their services.
        </p>
        <a href='https://policies.google.com/technologies/partner-sites'>Link: How Google Use Information</a>
       
        <p>This game is designed as to comply with Google Play’s Families Policy using AdMob and an app for child-directed treatment. Google will take steps to disable interest-based advertising and remarketing ads. 
        </p>
        
        <h4>Unity: </h4>
        <p> Math Cheer application is developed the software engine created by Unity Engines, but It doesn't use Unity Analytics or any other Unity services for data analysis of the application. 
            For more information please refer to their privacy policy with the link given below</p>
        <a href='https://unity3d.com/legal/privacy-policy'>Link: Unity Privacy Policy</a>
       
      
        <h3>Changes to Privacy Policy</h3>
        <p>We reserve the right to modify our Privacy Policy at any time simply by posting such modification on this site and without any other notification. 
            Any such modification will be effective immediately upon posting on this site. 
            The use of the information we collect is always subject to the Privacy Policy that is in effect at the time when this information is collected. 
            You are advised to refer to the Privacy Policy from time to time to agree  with our current privacy practices.</p>
        <h3>Contact Information</h3>
        <p>If  you have  any questions regarding this privacy policy, you may contact us using the information below.</p>
        <a href='mailto: rhoeasdigital@gmail.com' target="_blank" rel="noopener noreferrer"> E-mail to Us</a>
        <h3> Last Updated</h3>
        <p>This Privacy Policy was last updated on 29 July, 2020</p>
        
        </div>

        return(
            <div className={styles.privacyPolicyMainDiv}>
                {mathCheerPrivacyPolicy}
            </div>
        )
    }
}

export default MCPrivacyPolicy;