import React from 'react';
import styles from './RhoeasGamesHeader.module.css';
class RhoeasGamesHeader extends React.Component{
    render() {
        return(
            <div className={styles.RhoeasGamesHeaderDiv}>
                <h1 className={styles.RhoeasGamesHeaderText}>RhoeasGames</h1>
            </div>
        )
    }
}

export default RhoeasGamesHeader;