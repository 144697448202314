import React from 'react';
import styles from './RhoeasGames.module.css';
import RhoeasGamesHeader from './RhoeasGamesHeader/RhoeasGamesHeader';
import MathCheer from './Math Cheer/MathCheer';


class RhoeasGames extends React.Component{
    render() {
        return(
            <div>
                <RhoeasGamesHeader/>
                <div className={styles.RhoeasGamesDiv}>
                    <MathCheer/>

                </div>
                
            </div>
        )
    }
}

export default RhoeasGames;