import React, {Component} from 'react';
import styles from './MathCheer.module.css';
import MathCheerIcon from './appicon.jpg';
import {Link} from 'react-router-dom';

class MathCheer extends Component {
    render() {
        return (
            <div className={styles.cardContainer}>
                <h1 className={styles.gameHeader}>Math Cheer</h1>
                <img src={MathCheerIcon} alt="math cheer"/>
                <h3 className={styles.subText}>Play and Learn Elementary Math with Cheer!</h3>
               <h1 className={styles.privacyPolicy}> <Link to='/mcprivacy'>Privacy Policy</Link></h1>
            </div>
        )
    }
}

export default MathCheer;